<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>直播带货</el-breadcrumb-item>
      <el-breadcrumb-item>宝贝口袋</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <h3>宝贝口袋</h3>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="历史记录" name="first">
          <el-table
            ref="showTableData"
            :data="showTableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="商品名称" width="180">
              <template slot-scope="scope">
                <div style="display: flex">
                  <img
                    width="45"
                    height="45"
                    :src="scope.row.goods.previewUrl"
                    alt="商品图"
                  />
                  &nbsp;&nbsp;
                  <div>
                    <div style="color: #435b86">
                      {{ scope.row.goods.goodsName }}
                    </div>
                    <div>ID:{{ scope.row.goodsId }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="goods.goodsPrice" label="当前价" width="180">
            </el-table-column>
            <el-table-column prop="goods.totalSales" label="销量">
            </el-table-column>
            <el-table-column prop="goods.stockNum" label="库存">
            </el-table-column>
            <!-- <el-table-column prop="opera" label="操作">
              <template slot-scope="scope">
                <a
                  href="#"
                  @click="toOffShelf(scope.row)"
                  style="text-decoration: none"
                  >{{ scope.row.status === 0 ? "下架" : "上架" }}</a
                >
              </template>
            </el-table-column> -->
          </el-table>
          <!-- <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination> -->
          <el-pagination
            background
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="queryInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="queryInfo.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="shopTotal"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="正在直播" name="second">
          <el-table
            ref="playTableData"
            :data="playTableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="商品名称" width="180">
              <template slot-scope="scope">
                <div style="display: flex">
                  <img
                    width="45"
                    height="45"
                    :src="scope.row.goods.previewUrl"
                    alt="商品图"
                  />
                  &nbsp;&nbsp;
                  <div>
                    <div style="color: #435b86">
                      {{ scope.row.goods.goodsName }}
                    </div>
                    <div>ID:{{ scope.row.goodsId }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="goods.goodsPrice" label="当前价" width="180">
            </el-table-column>
            <el-table-column prop="goods.totalSales" label="销量">
            </el-table-column>
            <el-table-column prop="goods.stockNum" label="库存">
            </el-table-column>
            <el-table-column prop="opera" label="操作">
              <template slot-scope="scope">
                <a
                  href="#"
                  @click="toOffShelf(scope.row)"
                  style="text-decoration: none"
                  >{{ scope.row.status === 0 ? "下架" : "上架" }}</a
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import {
  playShoppingList,
  offShelfShop,
  searchBusinessInfoByUserId,
  getMyLive,
  getGoodsListOfLive,
} from "@/api";

export default {
  data() {
    return {
      // status: true,
      activeName: "first",
      // babyDialogVisible: false,
      queryInfo: {
        current: 1,
        size: 10,
        order: "desc",
        keyword: "",
        businessId: 0,
        // page: 1,
      },
      myLiveInfo: { user_id: window.sessionStorage.getItem("userId") },

      tableData: [
        {
          name: "安阳扁粉菜",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
          opera: "下架",
        },
        {
          name: "安阳扁粉菜2",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
          opera: "下架",
        },
        {
          name: "安阳扁粉菜3",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
          opera: "下架",
        },
        {
          name: "安阳扁粉菜4",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
        {
          name: "安阳扁粉菜5",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
      ],
      shopTotal: 0,
      showTableData: [],
      multipleSelection: [],
      dialogMultipleSelection: [],
      playTableData: [],
    };
  },
  methods: {
    _handleSizeChange(newSize) {
      this.queryInfo.size = newSize;
      if (this.tableData.length !== 0) {
        this.showTableData = this.tableData.slice(0, newSize);
      }
      this.queryInfo.current = 1;
      // this.getShoppingList();
      console.log(`每页 ${newSize} 条`);
    },
    handleClick(tab, event) {
      if (this.activeName === "second") {
        this.getGoodsList();
        // this.activeName = "second";
      }
      // else {
      //   this.activeName === "first";
      // }

      console.log(tab, event);
    },
    _handleCurrentChange(data) {
      this.queryInfo.current = data;
      if (this.tableData.length !== 0) {
        // if(this.queryInfo.size === 10 ){
        const size = this.queryInfo.size;
        this.showTableData = this.tableData.slice(
          (val - 1) * size,
          (val - 1) * size + size
        );
        // }
      }

      console.log(`当前页: ${val}`);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // this.babyDialogVisible = true;
    },
    async getGoodsList() {
      const { data: myLiveData } = await getMyLive(this.myLiveInfo);

      const { data: res } = await getGoodsListOfLive({ roomId: myLiveData.id });
      console.log(res, "goodsList");
      this.playTableData = res.body;
      // this.showTableData = res.body.goods.slice(0, 10);
      // this.shopTotal = res.body.total;
    },
    async getShoppingList() {
      const { data: businessDatas } = await searchBusinessInfoByUserId({
        userId: this.myLiveInfo.user_id,
      });
      this.queryInfo.businessId = businessDatas.body.id;
      const { data: res } = await playShoppingList(this.queryInfo);
      this.tableData = res.body.list;
      this.showTableData = res.body.list.slice(0, 10);
      this.shopTotal = res.body.total;
    },
    async toOffShelf(shopItem) {
      await offShelfShop({
        id: shopItem.id,
        status: shopItem.status === 0 ? 1 : 0,
      });
      // this.status = !this.status;

      this.getGoodsList();
    },
  },
  created() {
    this.getShoppingList();
  },
};
</script>

<style scoped>
.content {
  background: #fff;
  padding: 20px;
}
.el-table .title-row {
  background: #f3f7fa;
}
</style>
